'use client';

import Image from 'next/image'
import styles from './index.module.scss';
import logo from '@/app/images/logo.svg';
import menu from '@/app/icons/menu.svg';
import {useState, useCallback, useEffect, useRef} from "react";
import MainButton from "@/app/_components/Shared/MainButton";
import {navItems} from "./data";
import Link from 'next/link'
import { usePathname } from 'next/navigation'

export default function Nav() {
    const path = usePathname().replace(/\/$/, '');
    const [showMobileNav, setShowMobileNav] = useState(false);
    const navRef = useRef(null); // Create a reference for the nav container

    const items = useCallback((className) => {
        return <ul className={className}>
            {navItems.map((item, index) => (
                <li key={index} className={item.link === path ? styles.active : null}>
                    <Link href={item.link}>{item.text}</Link>
                </li>
            ))}
        </ul>;
    }, [path]);

    const toggleMobileNav = useCallback(() => {
        setShowMobileNav(!showMobileNav);
    }, [showMobileNav]);

    // hide mobile nav on route change
    useEffect(() => {
        setShowMobileNav(false);
    }, [path]);

    // hide mobile nav on click outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                setShowMobileNav(false);
            }
        };

        if (showMobileNav) {
            document.addEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showMobileNav]);

    // hide mobile nav on resize
    useEffect(() => {
        const hideMobileNav = () => setShowMobileNav(false);
        window.addEventListener('resize', hideMobileNav);
        return () => window.removeEventListener('resize', hideMobileNav);
    }, []);

    return (
        <>
            <nav className={styles.container} ref={navRef}>
                <div className={styles.nav}>
                    <div className="container-wrapper">
                        <Link href="/">
                            <Image priority={true} className={styles.logo} src={logo} alt="Logo"/>
                        </Link>
                        {items(styles.list)}
                        <div className={styles.buttons}>
                            <MainButton>
                                Login
                            </MainButton>
                            <MainButton color="secondary">
                                Sign Up
                            </MainButton>
                            <div className={styles.mobileNav} onClick={toggleMobileNav}>
                                <Image className={styles.menu} src={menu} alt="Menu"/>
                            </div>
                        </div>
                    </div>
                </div>
                {showMobileNav && items(styles.mobileList)}
            </nav>
        </>
);
}